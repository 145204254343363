
import Vue from 'vue'
import dayjs from 'dayjs'

import ScrollablePanels from '@/components/molecules/ScrollablePanels2.vue'
import DatePager from '@/components/atoms/DatePager.vue'

import { dailyScheduleModule } from '@/store/viewModules/work-schedule/dailyShceduleModule'
import { ShiftPatternResponse, WorkSchedulePlacementSchema } from 'chaild-api/lib'

interface DataType {
  isFetching: boolean;
  isCalcOpen: boolean;
}

interface DataRow {
  name: string;
  items: ScheduleHour[];
}

interface ScheduleHour {
  hour: number;
  items: QuarterlyShcedule[];
}

interface QuarterlyShcedule {
  abbreviation?: string | null;
  color?: string | null;
  isFilled: boolean;
  inSchool?: boolean;
  minute: number;
  requisite?: {
    govExpected: number | null;
    govActual: number | null;
    expected: number | null;
    actual: number | null;
  };
  sufficient?: {
    expected: number | null;
    actual: number | null;
  };
  attendance?: {
    age0: number | null;
    age1: number | null;
    age2: number | null;
    age3: number | null;
    age4: number | null;
  };
}

const minutes = [0, 15, 30, 45]
const hours = [...Array(24)].map((emp, i) => i)
const emptySchedule: ScheduleHour[] = hours.map((hour) => {
  const items: QuarterlyShcedule[] = minutes.map((minute) => ({
    isFilled: false,
    minute
  }))
  return {
    hour,
    items
  }
})

export default Vue.extend({
  components: {
    ScrollablePanels,
    DatePager,
  },
  data: (): DataType => ({
    isFetching: false,
    isCalcOpen: true,
  }),
  computed: {
    yearMonthDate: {
      get() {
        return dailyScheduleModule.yearMonthDate
      },
      set(value: string) {
        dailyScheduleModule.setYearMonthDate(value)
        this.fetchDailySchedule()
      }
    },
    dailySchedule(): ShiftPatternResponse[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.items
      }
      return []
    },
    placements(): WorkSchedulePlacementSchema[] {
      if (dailyScheduleModule.dailySchedule) {
        return dailyScheduleModule.dailySchedule.placements
      }
      return []
    },
    minutes(): number[] {
      return [0, 15, 30, 45]
    },
    hours() {
      return [...Array(24)].map((emp, i) => i)
    },
    emptySchedule(): ScheduleHour[] {
      return this.hours.map((hour) => {
        const items: QuarterlyShcedule[] = this.minutes.map((minute) => ({
          isFilled: false,
          minute
        }))
        return {
          hour,
          items
        }
      })
    },
    scheduleTableRows(): DataRow[] {
      return this.dailySchedule.map((schedule) => {
        let name = `${schedule.nurseryUser.lastName} ${schedule.nurseryUser.firstName}`
        if (name.length > 10) {
          name = name.slice(0, 10) + '...'
        }

        const items = [...this.emptySchedule].map((hourItem) => {
          const minItems = hourItem.items.map((minute) => {
            const timeAsInt = (hourItem.hour * 60) + minute.minute

            const findSchedule = schedule.timetables.find((tt) => tt.startMin <= timeAsInt && tt.endMin > timeAsInt)
            const findAttendance = schedule.timecards.find((tc) => tc.startMin <= timeAsInt && tc.endMin > timeAsInt);

            if (findSchedule) {
              return {
                isFilled: true,
                inSchool: findAttendance ? findAttendance.inSchool : false,
                abbreviation: findSchedule.abbreviation,
                color: findSchedule.color,
                minute: minute.minute
              }
            }

            return {
              isFilled: false,
              inSchool: findAttendance ? findAttendance.inSchool : false,
              minute: minute.minute
            }
          })

          return {
            hour: hourItem.hour,
            items: minItems
          }
        })

        return {
          name,
          items
        }
      })
    },
    tableCols() {
      return emptySchedule.map((s) => {
        const items = s.items.map((item) => {
          const min = s.hour * 60 + item.minute
          const find = this.placements.find((p) => {
            return p.startMin === min
          })
          console.log(find)
          if (find) {
            item.requisite = find.requisite
            item.sufficient = find.sufficient
            item.attendance = find.attendance?.expected
          }
          return item
        })
        return {
          hour: s.hour,
          items
        }
      })
    },
  },
  methods: {
    async fetchDailySchedule() {
      this.isFetching = true
      dailyScheduleModule.setDailySchedule(null)
      await dailyScheduleModule.getDailySchedule()
      this.isFetching = false
    },
    async downloadShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    async downloadMonthlyShiftPattern() {
      this.isFetching = true;
      const response = await dailyScheduleModule.dlMonthlyShiftPattern();
      if (response) {
        alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
      }
      this.isFetching = false;
    },
    toggleCalc() {
      this.isCalcOpen = !this.isCalcOpen
    }
  },
  mounted() {
    const queries = this.$route.query
    if (queries.date) {
      const dateString = queries.date as string
      const date = dayjs(dateString).format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    } else {
      const date = dayjs().format('YYYY-MM-DD')
      dailyScheduleModule.setYearMonthDate(date)
    }

    this.fetchDailySchedule()
  }
})
